import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { rgba } from "polished";
import { graphql, PageProps } from "gatsby";
import { CaseStudyMarkdownRemark } from "../types/case-study-markdown";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import { device } from "../utils";

import CaseList from "../sections/landing1/Testimonial";
import CTA from "../sections/case-study/CTA";

const Quotes = styled.span`
  font-size: 3rem;
  font-weight: 700;
  margin: 0 10px;
  color: #f04037;
`;

const Header = styled(Title)`
  font-size: 4.5rem;
`;
const Quote = styled(Text)`
  font-size: 1.5rem;
`;
const ParaText = styled(Text)`
  padding-bottom: 1rem;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
  margin-bottom: 10px;
  justify-content: space-between;

  @media ${device.sm} {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 25px;
      height: 25px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 0.8rem;
      margin-right: 0.2rem;
    }
  }
`;

type GraphQLResult = {
  markdownRemark: CaseStudyMarkdownRemark;
};

const CaseStudyDetails: React.FC<PageProps<GraphQLResult>> = ({ data }) => {
  const {
    hero,
    client,
    challenges,
    solutions,
    results,
  } = data.markdownRemark.frontmatter;
  return (
    <>
      <PageWrapper footerDark>
        <Helmet>
          <title>{hero.title} Case study</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Section
          backgroundImage={`url(${hero.headerImage.childImageSharp.fluid.src})`}
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          position="relative"
        >
          <Overlay />
          <div className="pt-5" />
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box className="text-center" mb={4}>
                  <img
                    src={hero.brandImage.childImageSharp.fluid.src}
                    alt=""
                    className="img-fluid"
                  />
                </Box>
                <Header variant="hero">{hero.title}</Header>
                <Quote>
                  <Quotes>&quot;</Quotes>
                  {hero.quote}
                  <Quotes>&quot;</Quotes>
                </Quote>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="position-relative">
          <Container>
            <Row>
              <Col lg="12" xl="10" className="offset-xl-1">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    {client.overviewTitle}
                  </Title>
                  <ParaText variant="small">
                    {client.overviewTextPara1}
                  </ParaText>
                </Box>
                <Box>
                  <Title variant="card" mb="18px">
                    {client.aboutTitle}
                  </Title>
                  <ParaText variant="small">{client.aboutTextPara1}</ParaText>
                  {client.aboutTextPara2 && (
                    <ParaText variant="small">{client.aboutTextPara2}</ParaText>
                  )}
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg="#f6f6f8" py={1}>
          <Container>
            <Row className="pt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    {challenges.challengesTitle}
                  </Title>
                  {challenges.points && (
                    <>
                      <ULStyled>
                        <li>{challenges.points[0].point1}</li>
                        <li>{challenges.points[0].point2}</li>
                        <li>{challenges.points[0].point3}</li>
                      </ULStyled>
                    </>
                  )}
                  <ParaText variant="small">{challenges.paragraph1}</ParaText>
                  {challenges.paragraph2 && (
                    <ParaText variant="small">{challenges.paragraph2}</ParaText>
                  )}
                  {challenges.paragraph3 && (
                    <ParaText variant="small">{challenges.paragraph3}</ParaText>
                  )}
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section py={1}>
          <Container>
            <Row className="mt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    {solutions.solutionsTitle}
                  </Title>
                  <ParaText variant="small">{solutions.paragraph1}</ParaText>
                  {solutions.paragraph2 && (
                    <ParaText variant="small">{solutions.paragraph2}</ParaText>
                  )}
                  {solutions.paragraph3 && (
                    <ParaText variant="small">{solutions.paragraph3}</ParaText>
                  )}
                  {solutions.paragraph4 && (
                    <ParaText variant="small">{solutions.paragraph4}</ParaText>
                  )}
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        {results && <Section bg="#f6f6f8" py={1}>
          <Container>
            <Row className="mt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    {results.resultsTitle}
                  </Title>
                  {results.points && (
                    <>
                      <ULStyled>
                        <li>{results.points[0].point1}</li>
                        <li>{results.points[0].point2}</li>
                        <li>{results.points[0].point3}</li>
                      </ULStyled>
                    </>
                  )}
                  <ParaText variant="small">{results.paragraph1}</ParaText>
                  {results.paragraph2 && (
                    <ParaText variant="small">{results.paragraph2}</ParaText>
                  )}
                  {results.paragraph3 && (
                    <ParaText variant="small">{results.paragraph3}</ParaText>
                  )}
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>}
        <CaseList />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudyDetails;

export const query = graphql`
  query CaseStudiesDetails($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        hero {
          title
          quote
          headerImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          brandImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        client {
          overviewTitle
          overviewTextPara1
          aboutTitle
          aboutTextPara1
          aboutTextPara2
        }
        challenges {
          challengesTitle
          points {
            point1
            point2
            point3
          }
          paragraph1
          paragraph2
          paragraph3
        }
        solutions {
          solutionsTitle
          paragraph1
          paragraph2
          paragraph3
          paragraph4
        }

        results {
          resultsTitle
          points {
            point1
            point2
            point3
          }
          paragraph1
          paragraph2
          paragraph3
        }
      }
    }
  }
`;
